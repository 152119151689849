<template>
  <div class="school-data-detail">
    <a
      class="text-decoration-none text-subtitle-2 font-weight-bold"
      @click.prevent="returnBack"
    >
      <v-icon
        color="primary"
        dense
        class="mr-2"
      >
        {{ icons.mdiArrowLeft }}
      </v-icon>
      Kembali
    </a>
    <div class="my-6">
      <v-card>
        <v-img
          height="400"
          :src="school.image"
        ></v-img>
        <v-card-text class="py-4">
          <h2 class="font-weight-bold mb-2">
            {{ school.name }}
          </h2>
          <div class="d-flex mb-2">
            <div class="d-flex align-center mb-2">
              <v-img
                :src="icons.iconLocation"
                width="20"
              ></v-img> <span class="ml-4">{{ school.place.address }}</span>
            </div>
          </div>
          <div class="d-flex mb-2">
            <div class="d-flex align-center mb-2">
              <v-img
                :src="icons.iconClip"
                width="20"
              ></v-img> <span class="ml-4">link</span>
            </div>
          </div>
        </v-card-text>
      </v-card>
      <v-row class="mt-2">
        <v-col
          v-for="card in cards"
          :key="card.title"
          md="3"
          lg="3"
          cols="12"
        >
          <v-card>
            <v-card-text class="d-flex">
              <div class="mr-4">
                <v-avatar :color="card.color">
                  <div>
                    <v-img
                      sizes="20"
                      :src="card.icon"
                    ></v-img>
                  </div>
                </v-avatar>
              </div>
              <div>
                <h3 class="font-weight-light">
                  {{ card.title }}
                </h3>
                <h2 class="font-weight-bold mb-2">
                  {{ card.total }}
                </h2>
                <router-link
                  class="text-decoration-none"
                  :to="{ name: card.to}"
                >
                  Lihat Detail
                </router-link>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
          cols="12"
        >
          <v-card class="pa-5">
            <v-row class="align-center">
              <v-col
                xl="5"
                lg="5"
                md="5"
                sm="12"
                cols="12"
              >
                <span class="text-subtitle-1 black--text font-weight-bold">Absensi Siswa</span>
              </v-col>
              <v-col
                xl="1"
                lg="1"
                md="1"
                sm="12"
                cols="12"
                class="d-none d-sm-none d-md-block d-lg-block d-xl-block"
              ></v-col>
              <v-col
                xl="6"
                lg="6"
                md="6"
                sm="12"
                cols="12"
              >
                <div>
                  <v-autocomplete
                    :label="dateNow"
                    outlined
                    :items="date"
                    item-text="name"
                    item-value="id"
                    dense
                    class="filter"
                    @change="handleFilter($event, 'attendance')"
                  ></v-autocomplete>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <apex-chart-student-attendance-ratio :count="countStudentAttendance"></apex-chart-student-attendance-ratio>
              </v-col>
            </v-row>
            <v-card-actions class="my-4 justify-center pa-0">
              <router-link
                class="text-decoration-none"
                :to="{name:'school-data-hob-student-attendance'}"
              >
                Lihat Detail
              </router-link>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
          cols="12"
        >
          <v-card class="pa-5">
            <v-row class="align-center">
              <v-col
                xl="5"
                lg="5"
                md="5"
                sm="12"
                cols="12"
              >
                <span class="text-subtitle-1 black--text font-weight-bold">Dana Bos</span>
              </v-col>
              <v-col
                xl="1"
                lg="1"
                md="1"
                sm="12"
                cols="12"
                class="d-none d-sm-none d-md-block d-lg-block d-xl-block"
              ></v-col>
              <v-col
                xl="6"
                lg="6"
                md="6"
                sm="12"
                cols="12"
              >
                <div>
                  <v-autocomplete
                    :label="dateNow"
                    outlined
                    :items="date"
                    item-text="name"
                    item-value="id"
                    dense
                    class="filter"
                    @change="handleFilter($event, 'fund')"
                  ></v-autocomplete>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <apex-chart-fund-ratio :count="countFund"></apex-chart-fund-ratio>
              </v-col>
            </v-row>
            <v-card-actions class="my-4 justify-center pa-0">
              <router-link
                class="text-decoration-none"
                :to="{name: 'school-data-hob-fund'}"
              >
                Lihat Detail
              </router-link>
              <!-- <a :href="`fund`">Lihat</a> -->
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mdiArrowLeft } from '@mdi/js'
import iconClip from '../../../assets/icons/clip.svg'
import iconLocation from '../../../assets/icons/location.svg'
import iconMonitor from '../../../assets/icons/white/monitor.svg'
import iconBank from '../../../assets/icons/white/receipt-2.svg'
import iconUser from '../../../assets/icons/white/user.svg'
import ApexChartFundRatio from './ApexChartFundRatio.vue'
import ApexChartStudentAttendanceRatio from './ApexChartStudentAttendanceRatio.vue'

export default {
  name: 'SchoolDataDetail',
  components: {
    ApexChartStudentAttendanceRatio,
    ApexChartFundRatio,
  },
  data() {
    return {
      school: {
        place: {},
      },
      school_uuid: '',
      icons: {
        mdiArrowLeft,
        iconLocation,
        iconUser,
        iconClip,
        iconMonitor,
        iconBank,
      },
      detail: {},
      total_student: 0,
      cards: [
        {
          title: 'Total Siswa',
          total: 0,
          icon: iconUser,
          color: '#EC008C',
          to: 'school-data-hob-student',
        },
        {
          title: 'Total Guru',
          total: 0,
          icon: iconUser,
          color: '#7D2B8B',
          to: 'school-data-hob-teacher',
        },
        {
          title: 'Bank Soal',
          total: 0,
          icon: iconBank,
          color: '#7D2B8B',
          to: 'school-data-hob-bankquestion',
        },
        {
          title: 'CBT',
          total: 0,
          icon: iconMonitor,
          color: '#2197D8',
          to: 'school-data-hob-cbt',
        },
      ],
      service: 'school',
      date: [
        {
          id: `01-${new Date().getFullYear()}`,
          name: `January ${new Date().getFullYear()}`,
        },
        {
          id: `02-${new Date().getFullYear()}`,
          name: `February ${new Date().getFullYear()}`,
        },
        {
          id: `03-${new Date().getFullYear()}`,
          name: `March ${new Date().getFullYear()}`,
        },
        {
          id: `04-${new Date().getFullYear()}`,
          name: `April ${new Date().getFullYear()}`,
        },
        {
          id: `05-${new Date().getFullYear()}`,
          name: `May ${new Date().getFullYear()}`,
        },
        {
          id: `06-${new Date().getFullYear()}`,
          name: `June ${new Date().getFullYear()}`,
        },
        {
          id: `07-${new Date().getFullYear()}`,
          name: `July ${new Date().getFullYear()}`,
        },
        {
          id: `08-${new Date().getFullYear()}`,
          name: `August ${new Date().getFullYear()}`,
        },
        {
          id: `09-${new Date().getFullYear()}`,
          name: `September ${new Date().getFullYear()}`,
        },
        {
          id: `10-${new Date().getFullYear()}`,
          name: `October ${new Date().getFullYear()}`,
        },
        {
          id: `11-${new Date().getFullYear()}`,
          name: `November ${new Date().getFullYear()}`,
        },
        {
          id: `12-${new Date().getFullYear()}`,
          name: `December ${new Date().getFullYear()}`,
        },
      ],
      countStudentAttendance: [0, 0, 0, 0],
      countFund: [0, 0, 0],
      paramsDate: '',
      dateNow: '',
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    }
  },
  async mounted() {
    this.getSchool(this.$route.params.uuid)
    this.getSchoolUuid()
    this.dateNow = `${this.monthNames[new Date().getMonth()]} ${new Date().getFullYear()}`
    this.checkFirstParams()
    await this.getDetail({ date: this.paramsDate })
  },
  methods: {
    returnBack() {
      this.$router.back()
    },
    checkFirstParams() {
      this.date.forEach(item => {
        if (item.name === this.dateNow) this.paramsDate = item.id
      })
    },
    async getChartFund(params = {}) {
      await this.$services.ApiServices.getDetailSchool('kadis', { ...params }, this.school_uuid).then(({ data }) => {
        this.countFund = Object.values(data.data.fund)
      })
    },
    async getChartAttendance(params = {}) {
      await this.$services.ApiServices.getDetailSchool('kadis', { ...params }, this.school_uuid).then(({ data }) => {
        this.countStudentAttendance = Object.values(data.data.absen)
      })
    },
    async handleFilter(id, type) {
      if (type === 'attendance') {
        await this.getChartAttendance({ date: id })

        return
      }
      await this.getChartFund({ date: id })
    },
    async getSchool(uuid) {
      await this.$services.ApiServices.get(this.service, uuid).then(({ data }) => {
        this.school = data.data
      })
    },
    getSchoolUuid() {
      this.school_uuid = this.$route.params.uuid
    },
    async getDetail(params = {}) {
      await this.$services.ApiServices.getDetailSchool('hob', { ...params }, this.school_uuid).then(({ data }) => {
        this.detail = data.data
        this.countStudentAttendance = Object.values(this.detail.absen)
        this.countFund = Object.values(this.detail.fund)
        this.cards[0].total = data.data.count_student
        this.cards[1].total = data.data.count_teacher
        this.cards[2].total = data.data.count_questionbank
        this.cards[3].total = data.data.count_cbt
      })
    },
    // async getStudent(params = {}) {
    //   await this.$services.ApiServices.list('student', {
    //     ...params,
    //     school_uuid: this.school_uuid,
    //     per_page: 'all',
    //   }).then(({ data }) => {
    //     this.students = data.data
    //     this.cards[0].total = data.meta.total
    //   })
    // },
  },
}
</script>

<style>
</style>
